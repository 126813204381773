import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Typography, Space, Breadcrumb } from 'antd';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import { toolCategories, Tool } from '../data/tools';
import './ToolDetail.scss';
import ImageResizer from '../components/tools/ImageResizer';
import ImageCompressor from '../components/tools/ImageCompressor';

const { Title, Paragraph } = Typography;

const ToolDetail: React.FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    
    // 从 params 中安全地获取参数
    const categoryId = params.categoryId || '';
    const toolId = params.toolId || '';

    // 查找当前工具和分类
    const { category, tool } = useMemo(() => {
        const category = toolCategories.find(c => c.id === categoryId);
        const tool = category?.tools.find(t => t.name === decodeURIComponent(toolId));
        return { category, tool };
    }, [categoryId, toolId]);

    // 如果找不到工具，显示错误信息
    if (!category || !tool) {
        return (
            <div className="tool-detail-page error">
                <Title level={2}>工具未找到</Title>
                <Button type="primary" onClick={() => navigate('/categories')}>
                    返回工具列表
                </Button>
            </div>
        );
    }

    return (
        <div className="tool-detail-page">
            {/* 面包屑导航 */}
            <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item href="/">
                    <HomeOutlined />
                    <span>首页</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item href="/categories">工具分类</Breadcrumb.Item>
                <Breadcrumb.Item>{category.title}</Breadcrumb.Item>
                <Breadcrumb.Item>{tool.name}</Breadcrumb.Item>
            </Breadcrumb>

            {/* 工具标题区域 */}
            <div className="tool-header">
                <Button 
                    type="text"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => navigate(-1)}
                    className="back-button"
                    size="small"
                >
                    返回
                </Button>
                <Space className="title-wrapper">
                    <Title level={2}>{tool.name}</Title>
                    {tool.hot && <span className="hot-tag">热门</span>}
                </Space>
                <Paragraph>{tool.description}</Paragraph>
            </div>

            {/* 工具主内容区域 */}
            <Card className="tool-content">
                {/* 这里根据不同的工具类型渲染不同的组件 */}
                {renderToolContent(tool)}
            </Card>
        </div>
    );
};

// 根据工具类型渲染对应的组件
const renderToolContent = (tool: Tool) => {
    switch (tool.name) {
        case '图片尺寸调整':
            return <ImageResizer />;
        case '图片压缩':
            return <ImageCompressor />;
        default:
            return (
                <div className="tool-placeholder">
                    <Title level={3}>工具开发中...</Title>
                    <Paragraph>
                        {tool.name} 工具正在开发中，敬请期待！
                    </Paragraph>
                </div>
            );
    }
};

export default ToolDetail; 