export type ToolType = 'text' | 'image' | 'dev' | 'format' | 'crypto' | 'calc';

export interface Tool {
    name: string;
    description: string;
    hot?: boolean;
    icon: string;
    type: ToolType;
    component?: string;  // 用于动态加载对应的工具组件
}

export interface Category {
    id: string;
    title: string;
    icon: string;
    description: string;
    tools: Tool[];
    order?: number;  // 用于排序
}

export const toolCategories: Category[] = [
    {
        id: 'image',
        title: '图片处理',
        icon: '🖼️',
        description: '图片编辑、转换、优化工具集',
        tools: [
            { name: '图片压缩', description: '智能图片压缩工具', hot: true, icon: '📦', type: 'image' },
            { name: '图片格式转换', description: '支持多种格式互转', icon: '🔄', type: 'image' },
            { name: '图片尺寸调整', description: '快速调整图片尺寸大小', hot: true, icon: '📐', type: 'image' },
            { name: '图片水印', description: '添加文字或图片水印', icon: '📝', type: 'image' },
            { name: '图片滤镜', description: '专业的图片滤镜效果', icon: '🎨', type: 'image' },
            { name: 'AI图片放大', description: 'AI智能无损放大图片', hot: true, icon: '🔍', type: 'image' }
        ]
    },
    {
        id: 'text',
        title: '文本处理',
        icon: '📝',
        description: '各类文本处理、格式化、转换工具',
        tools: [
            { name: 'JSON格式化', description: '在线JSON格式化、校验工具', hot: true, icon: '📋', type: 'text' },
            { name: 'XML转换器', description: 'XML与其他格式互转工具', icon: '🔄', type: 'text' },
            { name: '文本对比工具', description: '快速对比文本差异', icon: '🔍', type: 'text' },
            { name: 'Markdown编辑器', description: '所见即所得的Markdown编辑器', hot: true, icon: '📝', type: 'text' },
            { name: 'Base64编解码', description: 'Base64在线编码解码工具', icon: '🔒', type: 'text' },
            { name: '文本加密解密', description: '支持多种加密算法', icon: '🔐', type: 'text' }
        ]
    }
]; 
