import React from 'react';
import { Typography, Form, Input, Button, Card, message } from 'antd';
import { MailOutlined, GithubOutlined, WechatOutlined } from '@ant-design/icons';
import './Contact.scss';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const Contact: React.FC = () => {
    const [form] = Form.useForm();

    const handleSubmit = (values: any) => {
        console.log('Received values:', values);
        message.success('消息已发送，我们会尽快回复！');
        form.resetFields();
    };

    return (
        <div className="contact-page">
            <div className="page-header">
                <Title level={2}>联系我们</Title>
                <Paragraph className="intro">
                    如果您有任何问题、建议或合作意向，欢迎通过以下方式与我们联系。
                </Paragraph>
            </div>

            <div className="contact-content">
                <Card className="contact-info">
                    <Title level={3}>联系方式</Title>
                    <div className="info-item">
                        <MailOutlined className="icon" />
                        <div className="details">
                            <h4>电子邮件</h4>
                            <p>support@toolportal.com</p>
                        </div>
                    </div>
                    <div className="info-item">
                        <GithubOutlined className="icon" />
                        <div className="details">
                            <h4>GitHub</h4>
                            <p>github.com/toolportal</p>
                        </div>
                    </div>
                    <div className="info-item">
                        <WechatOutlined className="icon" />
                        <div className="details">
                            <h4>微信公众号</h4>
                            <p>ToolPortal</p>
                        </div>
                    </div>
                </Card>

                <Card className="contact-form">
                    <Title level={3}>留言反馈</Title>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            name="name"
                            label="姓名"
                            rules={[{ required: true, message: '请输入您的姓名' }]}
                        >
                            <Input placeholder="请输入您的姓名" />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            label="邮箱"
                            rules={[
                                { required: true, message: '请输入您的邮箱' },
                                { type: 'email', message: '请输入有效的邮箱地址' }
                            ]}
                        >
                            <Input placeholder="请输入您的邮箱" />
                        </Form.Item>

                        <Form.Item
                            name="message"
                            label="留言内容"
                            rules={[{ required: true, message: '请输入留言内容' }]}
                        >
                            <TextArea rows={4} placeholder="请输入您的留言内容" />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" block>
                                提交留言
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

export default Contact; 