import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Upload, Button, Space, message, Card, Slider, Typography, Image as AntImage, Spin } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import './ImageCompressor.scss';

const { Text } = Typography;

// 锐化算法
const applySharpen = (imageData: ImageData): ImageData => {
    const { width, height, data } = imageData;
    const output = new ImageData(width, height);
    const outputData = output.data;
    
    // 使用更强的锐化卷积核
    const kernel = [
        -0.15, -0.15, -0.15,
        -0.15,  2.2,  -0.15,
        -0.15, -0.15, -0.15
    ];

    for (let y = 1; y < height - 1; y++) {
        for (let x = 1; x < width - 1; x++) {
            const idx = (y * width + x) * 4;
            let r = 0, g = 0, b = 0;

            // 应用锐化
            for (let ky = -1; ky <= 1; ky++) {
                for (let kx = -1; kx <= 1; kx++) {
                    const offset = ((y + ky) * width + (x + kx)) * 4;
                    const kernelValue = kernel[(ky + 1) * 3 + (kx + 1)];
                    
                    r += data[offset] * kernelValue;
                    g += data[offset + 1] * kernelValue;
                    b += data[offset + 2] * kernelValue;
                }
            }

            // 确保值在有效范围内并增强对比度
            outputData[idx] = Math.min(255, Math.max(0, r + (r > 128 ? 5 : -5)));
            outputData[idx + 1] = Math.min(255, Math.max(0, g + (g > 128 ? 5 : -5)));
            outputData[idx + 2] = Math.min(255, Math.max(0, b + (b > 128 ? 5 : -5)));
            outputData[idx + 3] = data[idx + 3];
        }
    }

    return output;
};

// 缓存计算结果
const memoizedSharpen = (() => {
    const cache = new Map<string, ImageData>();
    return (imageData: ImageData, cacheKey: string): ImageData => {
        if (cache.has(cacheKey)) {
            return cache.get(cacheKey)!;
        }
        const result = applySharpen(imageData);
        if (cache.size > 10) {  // 限制缓存大小
            const firstKey = cache.keys().next().value;
            cache.delete(firstKey);
        }
        cache.set(cacheKey, result);
        return result;
    };
})();

const ImageCompressor: React.FC = () => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [compressedUrl, setCompressedUrl] = useState<string>('');
    const [quality, setQuality] = useState(80);
    const [originalSize, setOriginalSize] = useState(0);
    const [compressedSize, setCompressedSize] = useState(0);
    const [isCompressing, setIsCompressing] = useState(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    // 计算 base64 字符串对应的文件大小（字节）
    const getBase64Size = useCallback((base64String: string): number => {
        try {
            // 检查输入
            if (!base64String || typeof base64String !== 'string') {
                console.warn('Invalid base64 string');
                return 0;
            }

            // 移除 data:image/* 头部
            const base64 = base64String.split(',')[1];
            if (!base64) {
                console.warn('Invalid base64 format');
                return 0;
            }

            // 计算实际文件大小
            const sizeInBytes = Math.ceil((base64.length * 6) / 8);
            return sizeInBytes;
        } catch (error) {
            console.error('Error calculating base64 size:', error);
            return 0;
        }
    }, []);

    // 优化压缩函数
    const compressImage = useCallback(async (img: HTMLImageElement) => {
        try {
            const canvas = canvasRef.current;
            if (!canvas) return;

            // 设置画布尺寸
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d')!;

            // 使用高质量设置
            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = 'high';

            // 绘制原图
            ctx.drawImage(img, 0, 0);

            // 尝试不同的压缩策略
            const compressionStrategies = [
                { type: 'image/jpeg', quality: quality / 100 },
                { type: 'image/webp', quality: quality / 100 },
                { type: 'image/png', quality: 1 }
            ];

            let bestDataUrl = '';
            let bestSize = Infinity;

            // 测试每种压缩策略
            for (const strategy of compressionStrategies) {
                try {
                    const dataUrl = canvas.toDataURL(strategy.type, strategy.quality);
                    const size = getBase64Size(dataUrl);

                    if (size > 0 && size < bestSize) {
                        bestSize = size;
                        bestDataUrl = dataUrl;
                    }
                } catch (e) {
                    console.warn(`Compression failed for ${strategy.type}`, e);
                    continue;
                }
            }

            // 如果没有找到有效的压缩结果
            if (!bestDataUrl || bestSize === Infinity) {
                throw new Error('No valid compression result found');
            }

            // 如果压缩效果不理想，尝试更激进的压缩
            if (bestSize >= originalSize) {
                const jpegDataUrl = canvas.toDataURL('image/jpeg', 0.7);
                const jpegSize = getBase64Size(jpegDataUrl);
                
                if (jpegSize > 0 && jpegSize < bestSize) {
                    bestSize = jpegSize;
                    bestDataUrl = jpegDataUrl;
                }
            }

            // 更新压缩结果
            if (bestSize > 0 && bestSize < originalSize) {
                setCompressedUrl(bestDataUrl);
                setCompressedSize(bestSize);
                const ratio = ((1 - bestSize / originalSize) * 100).toFixed(1);
                message.success(`图片压缩完成！压缩率：${ratio}%`);
            } else {
                // 如果无法压缩，使用较高质量的 JPEG
                const fallbackDataUrl = canvas.toDataURL('image/jpeg', 0.9);
                const fallbackSize = getBase64Size(fallbackDataUrl);
                
                if (fallbackSize > 0) {
                    setCompressedUrl(fallbackDataUrl);
                    setCompressedSize(fallbackSize);
                    message.warning('图片已经很小，仅做轻微压缩');
                } else {
                    throw new Error('压缩后大小计算错误');
                }
            }
        } catch (error) {
            console.error('Compression error:', error);
            message.error('压缩过程中出现错误，请重试');
            setCompressedUrl('');
            setCompressedSize(0);
        } finally {
            setIsCompressing(false);
            message.destroy();
        }
    }, [quality, getBase64Size, originalSize]);

    // 使用 useMemo 优化计算，并添加保护
    const compressionRatio = useMemo(() => {
        if (!originalSize || !compressedSize || compressedSize > originalSize) return 0;
        const ratio = ((1 - compressedSize / originalSize) * 100);
        return ratio > 0 ? ratio.toFixed(1) : '0.0';
    }, [originalSize, compressedSize]);

    // 处理图片上传
    const handleUpload = async (file: File) => {
        try {
            setIsCompressing(true);
            message.loading('正在处理图片...', 0);

            const reader = new FileReader();
            reader.onload = async (e) => {
                const img = new window.Image();
                img.onload = () => {
                    setPreviewUrl(e.target?.result as string);
                    setOriginalSize(file.size);
                    compressImage(img);
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        } catch (error) {
            message.error('图片处理失败，请重试');
        }
        return false;
    };

    // 处理质量变化
    const handleQualityChange = (value: number) => {
        setQuality(value);
        if (previewUrl) {
            try {
                setIsCompressing(true);
                const img = new window.Image();
                img.onload = () => compressImage(img);
                img.onerror = () => {
                    setIsCompressing(false);
                    message.error('图片加载失败');
                };
                img.src = previewUrl;
            } catch (error) {
                setIsCompressing(false);
                message.error('处理失败，请重试');
            }
        }
    };

    // 下载压缩后的图片
    const handleDownload = () => {
        if (!compressedUrl) {
            message.error('请先上传图片');
            return;
        }

        const a = document.createElement('a');
        a.href = compressedUrl;
        a.download = 'compressed-image.jpg';
        a.click();
    };

    return (
        <div className="image-compressor">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {/* 上传区域 */}
                <Card title="1. 选择图片">
                    <Upload
                        accept="image/*"
                        maxCount={1}
                        fileList={fileList}
                        beforeUpload={handleUpload}
                        onChange={({ fileList }) => setFileList(fileList)}
                        disabled={isCompressing}
                    >
                        <Button icon={<UploadOutlined />} disabled={isCompressing}>
                            选择图片
                        </Button>
                    </Upload>
                </Card>

                {/* 压缩设置 */}
                <Card title="2. 压缩设置">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div className="quality-control">
                            <div className="quality-label">
                                <span>压缩质量</span>
                                <span className="quality-value">{quality}%</span>
                            </div>
                            <Slider 
                                min={1} 
                                max={100} 
                                value={quality} 
                                onChange={handleQualityChange}
                                disabled={isCompressing}
                            />
                        </div>

                        {originalSize > 0 && (
                            <div className="size-info">
                                <Text>原始大小：{(originalSize / 1024).toFixed(2)} KB</Text>
                                <Text>压缩后大小：{(compressedSize / 1024).toFixed(2)} KB</Text>
                                <Text type="success">
                                    压缩率：{compressionRatio}%
                                </Text>
                            </div>
                        )}
                    </Space>
                </Card>

                {/* 预览区域 */}
                {previewUrl && (
                    <Card title="3. 预览">
                        <Spin spinning={isCompressing} tip="正在压缩图片...">
                            <div className="preview-area">
                                <div className="image-container">
                                    <div className="image-wrapper">
                                        <h4>原图</h4>
                                        <AntImage
                                            src={previewUrl}
                                            alt="原图"
                                            className="preview-image"
                                        />
                                    </div>
                                    <div className="image-wrapper">
                                        <h4>压缩后</h4>
                                        <AntImage
                                            src={compressedUrl}
                                            alt="压缩后"
                                            className="preview-image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    </Card>
                )}

                {/* 下载按钮 */}
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={handleDownload}
                    disabled={!compressedUrl || isCompressing}
                    block
                >
                    下载压缩后的图片
                </Button>
            </Space>

            {/* 隐藏的canvas */}
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
    );
};

export default ImageCompressor; 