import React from 'react';
import { Row, Col, Card, Typography, Button, Carousel, message } from 'antd';
import { FireOutlined, StarOutlined, HistoryOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './Home.scss';

const { Title, Paragraph } = Typography;

// 定义轮播数据
const carouselItems = [
    {
        title: '在线工具集',
        description: '便捷、高效、专业的在线工具平台，助力提升您的工作效率',
        background: 'linear-gradient(120deg, #2B32B2 0%, #1488CC 100%)'
    },
    {
        title: 'AI 智能工具',
        description: '集成 Stable Diffusion、ChatGPT 等前沿 AI 技术，让创作更简单',
        background: 'linear-gradient(120deg, #5B247A 0%, #1BCEDF 100%)'
    },
    {
        title: '开发者工具箱',
        description: '提供代码格式化、API 测试、正则验证等多种开发工具',
        background: 'linear-gradient(120deg, #184E68 0%, #57CA85 100%)'
    },
    {
        title: '文件处理专家',
        description: 'PDF转换、图片处理、视频压缩，一站式解决所有文件处理需求',
        background: 'linear-gradient(120deg, #FF5858 0%, #F09819 100%)'
    }
];

// 定义工具分类数据
const categories = [
    {
        title: '文本处理',
        icon: '📝',
        tools: ['JSON格式化', 'XML转换', '文本对比', 'Markdown编辑器']
    },
    {
        title: '图片处理',
        icon: '🖼️',
        tools: ['图片压缩', '格式转换', '在线裁剪', '水印添加']
    },
    {
        title: '开发工具',
        icon: '⚙️',
        tools: ['代码格式化', 'API测试', '正则测试', '时间戳转换']
    },
    {
        title: '格式转换',
        icon: '🔄',
        tools: ['PDF转Word', '视频转换', '音频转换', '图片转换']
    },
    {
        title: '加密解密',
        icon: '🔒',
        tools: ['MD5加密', 'Base64', 'URL编码', 'JWT解析']
    },
    {
        title: '计算工具',
        icon: '🧮',
        tools: ['单位换算', '进制转换', '计算器', '公式计算']
    },
    {
        title: '网络工具',
        icon: '🌐',
        tools: ['IP查询', '端口扫描', 'Ping测试', 'DNS查询']
    },
    {
        title: '其他工具',
        icon: '🛠️',
        tools: ['二维生成', '短链生成', '随机数生成', '颜色选择器']
    }
];

// 定义热门工具数据
const hotTools = [
    {
        id: 1,
        title: 'AI 图片生成',
        description: '基于 Stable Diffusion 的智能图片生成工具',
        icon: '🎨',
        useCount: '1,234,567'
    },
    {
        id: 2,
        title: 'ChatGPT 提示词工具',
        description: '优化 AI 对话的提示词生成与管理',
        icon: '🤖',
        useCount: '986,432'
    },
    {
        id: 3,
        title: 'PDF 全能工具',
        description: '支持转换、压缩、编辑等多种操作',
        icon: '📄',
        useCount: '876,543'
    },
    {
        id: 4,
        title: '视频在线压缩',
        description: '高质量视频压缩，支持多种格式',
        icon: '🎥',
        useCount: '765,432'
    },
    {
        id: 5,
        title: '代码一键格式化',
        description: '支持多种编程语言的代码美化',
        icon: '💻',
        useCount: '654,321'
    },
    {
        id: 6,
        title: '图片批量处理',
        description: '压缩、裁剪、水印一站式解决',
        icon: '🖼️',
        useCount: '543,210'
    }
];

const Home: React.FC = () => {
    const navigate = useNavigate();

    // 处理工具使用按钮点击
    const handleUseToolClick = (toolName: string) => {
        // 根据工具名称跳转到对应的工具页面
        switch (toolName) {
            case 'AI 图片生成':
            case 'ChatGPT 提示词工具':
                message.info('AI 工具即将上线，敬请期待！');
                break;
            case 'PDF 全能工具':
            case '视频在线压缩':
                message.info('文件处理工具正在开发中...');
                break;
            case '代码一键格式化':
                navigate('/tool/dev/code-formatter');
                break;
            case '图片批量处理':
                navigate('/tool/image/batch-process');
                break;
            default:
                message.info('该工具正在开发中...');
        }
    };

    // 处理分类卡片点击
    const handleCategoryClick = (category: string) => {
        navigate(`/categories?type=${category.toLowerCase()}`);
    };

    return (
        <div className="home-page">
            {/* Hero Section with Carousel */}
            <div className="hero-section">
                <Carousel autoplay effect="fade">
                    {carouselItems.map((item, index) => (
                        <div key={index} className="carousel-item">
                            <div 
                                className="carousel-content"
                                style={{ 
                                    background: item.background
                                }}
                            >
                                <div className="hero-content">
                                    <Title level={1}>{item.title}</Title>
                                    <Paragraph>{item.description}</Paragraph>
                                    <Button 
                                        type="primary" 
                                        size="large" 
                                        className="hero-button"
                                        onClick={() => {
                                            message.info('正在为您准备最新工具...');
                                            navigate('/categories');
                                        }}
                                    >
                                        立即使用
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>

            {/* Content Container */}
            <div className="content-container">
                {/* 热门工具区域 */}
                <section className="tools-section">
                    <Title level={3}>
                        <FireOutlined /> 热门工具
                    </Title>
                    <Row gutter={[16, 16]}>
                        {hotTools.map(tool => (
                            <Col xs={24} sm={12} md={8} lg={8} key={tool.id}>
                                <Card 
                                    hoverable 
                                    className="tool-card"
                                >
                                    <div className="tool-header">
                                        <div className="tool-icon">{tool.icon}</div>
                                        <div className="tool-usage">
                                            <FireOutlined /> {tool.useCount}
                                        </div>
                                    </div>
                                    <Card.Meta
                                        title={tool.title}
                                        description={tool.description}
                                    />
                                    <Button 
                                        type="primary" 
                                        className="use-button"
                                        onClick={() => handleUseToolClick(tool.title)}
                                    >
                                        立即使用
                                    </Button>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </section>

                {/* 工具分类展示 */}
                <section className="categories-section">
                    <Title level={3}>
                        <StarOutlined /> 工具分类
                    </Title>
                    <Row gutter={[16, 16]}>
                        {categories.map(category => (
                            <Col xs={24} sm={12} md={8} lg={6} key={category.title}>
                                <Card 
                                    className="category-card" 
                                    hoverable
                                    onClick={() => handleCategoryClick(category.title)}
                                >
                                    <div className="category-icon">{category.icon}</div>
                                    <Title level={4}>{category.title}</Title>
                                    <div className="tool-list">
                                        {category.tools.map(tool => (
                                            <div key={tool} className="tool-item">
                                                {tool}
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </section>

                {/* 最近使用 */}
                <section className="recent-section">
                    <Title level={3}>
                        <HistoryOutlined /> 最近使用
                    </Title>
                    <Row gutter={[16, 16]}>
                        {[1, 2, 3].map(item => (
                            <Col xs={24} sm={12} md={8} key={item}>
                                <Card 
                                    className="recent-card" 
                                    hoverable
                                    onClick={() => message.info('历史记录功能开发中...')}
                                >
                                    <Card.Meta
                                        title="JSON格式化工具"
                                        description="上次使用：2024-03-14 15:30"
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </section>
            </div>
        </div>
    );
};

export default Home; 