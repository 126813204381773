import React from 'react';
import { Typography, Card, Row, Col, Button, message } from 'antd';
import { 
    ToolOutlined, 
    TeamOutlined, 
    SafetyCertificateOutlined,
    RocketOutlined,
    CodeOutlined,
    GlobalOutlined,
    ArrowRightOutlined 
} from '@ant-design/icons';
import './About.scss';

const { Title, Paragraph, Text } = Typography;

const About: React.FC = () => {
    return (
        <div className="about-page">
            <div className="hero-section">
                <div className="hero-content">
                    <Title>让工具使用更简单</Title>
                    <Paragraph className="intro">
                        Tool Portal 致力于打造最实用、最便捷的在线工具平台，
                        让每个用户都能轻松找到并使用所需的工具。
                    </Paragraph>
                    <div className="hero-stats">
                        <div className="stat-item">
                            <Text className="number">10+</Text>
                            <Text className="label">在线工具</Text>
                        </div>
                        <div className="stat-item">
                            <Text className="number">1000+</Text>
                            <Text className="label">日活用户</Text>
                        </div>
                        <div className="stat-item">
                            <Text className="number">99.9%</Text>
                            <Text className="label">服务可用性</Text>
                        </div>
                    </div>
                </div>
            </div>

            <Row gutter={[24, 24]} className="feature-cards">
                <Col xs={24} sm={8}>
                    <Card className="feature-card">
                        <div className="card-content">
                            <ToolOutlined className="icon" />
                            <Title level={4}>专业工具</Title>
                            <Paragraph>
                                提供图片处理、文本转换、开发工具等多种专业在线工具，满足不同用户的需求。
                            </Paragraph>
                            <div className="card-footer">
                                <Button 
                                    type="link" 
                                    icon={<ArrowRightOutlined />}
                                    className="learn-more-btn"
                                    onClick={() => message.info('功能开发中...')}
                                >
                                    了解更多
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={8}>
                    <Card className="feature-card">
                        <div className="card-content">
                            <TeamOutlined className="icon" />
                            <Title level={4}>用户至上</Title>
                            <Paragraph>
                                注重用户体验，持续优化工具性能和界面交互，提供更好的使用体验。
                            </Paragraph>
                            <div className="card-footer">
                                <Button 
                                    type="link" 
                                    icon={<ArrowRightOutlined />}
                                    className="learn-more-btn"
                                    onClick={() => message.info('功能开发中...')}
                                >
                                    了解更多
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={8}>
                    <Card className="feature-card">
                        <div className="card-content">
                            <SafetyCertificateOutlined className="icon" />
                            <Title level={4}>安全可靠</Title>
                            <Paragraph>
                                所有工具都在本地运行，不会上传您的文件，确保数据安全和隐私保护。
                            </Paragraph>
                            <div className="card-footer">
                                <Button 
                                    type="link" 
                                    icon={<ArrowRightOutlined />}
                                    className="learn-more-btn"
                                    onClick={() => message.info('功能开发中...')}
                                >
                                    了解更多
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>

            <div className="mission-section">
                <Row gutter={[48, 48]} align="middle">
                    <Col xs={24} md={12}>
                        <div className="mission-content">
                            <Title level={2}>我们的使命</Title>
                            <Paragraph>
                                通过技术创新，为用户提供更高效、更便捷的在线工具解决方案。
                            </Paragraph>
                            <ul className="mission-list">
                                <li>
                                    <RocketOutlined />
                                    <span>持续创新，追求卓越</span>
                                </li>
                                <li>
                                    <TeamOutlined />
                                    <span>用户至上，专注体验</span>
                                </li>
                                <li>
                                    <SafetyCertificateOutlined />
                                    <span>安全可靠，值得信赖</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="mission-image">
                            {/* 可以添加一张相关的图片或图标 */}
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="tech-section">
                <Title level={2} className="section-title">技术优势</Title>
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={8}>
                        <Card className="tech-card">
                            <CodeOutlined className="tech-icon" />
                            <Title level={4}>现代技术栈</Title>
                            <Paragraph>
                                采用 React、TypeScript 等现代化技术，确保工具的高性能和可靠性
                            </Paragraph>
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card className="tech-card">
                            <GlobalOutlined className="tech-icon" />
                            <Title level={4}>离线运行</Title>
                            <Paragraph>
                                所有工具支持本地运行，无需上传文件，保护用户隐私安全
                            </Paragraph>
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card className="tech-card">
                            <RocketOutlined className="tech-icon" />
                            <Title level={4}>持续优化</Title>
                            <Paragraph>
                                定期更新维护，不断优化性能，提供更好的用户体验
                            </Paragraph>
                        </Card>
                    </Col>
                </Row>
            </div>

            <div className="future-section">
                <Title level={2} className="section-title">未来规划</Title>
                <div className="timeline">
                    <div className="timeline-item">
                        <div className="time">2024 Q2</div>
                        <Card className="content">
                            <Title level={4}>工具库扩展</Title>
                            <Paragraph>
                                新增更多实用工具，优化现有功能
                            </Paragraph>
                        </Card>
                    </div>
                    <div className="timeline-item">
                        <div className="time">2024 Q3</div>
                        <Card className="content">
                            <Title level={4}>性能优化</Title>
                            <Paragraph>
                                提升工具运行效率，优化用户体验
                            </Paragraph>
                        </Card>
                    </div>
                    <div className="timeline-item">
                        <div className="time">2024 Q4</div>
                        <Card className="content">
                            <Title level={4}>AI 能力</Title>
                            <Paragraph>
                                引入 AI 增强工具功能
                            </Paragraph>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About; 