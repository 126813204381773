import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, Input, Button } from 'antd';
import './Header.scss';

const Header: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleMenuClick = (path: string) => {
        navigate(path);
    };

    return (
        <header className="header">
            <div className="logo" onClick={() => handleMenuClick('/')}>Tool Portal</div>
            <Menu 
                mode="horizontal" 
                selectedKeys={[location.pathname]}
            >
                <Menu.Item key="/" onClick={() => handleMenuClick('/')}>
                    首页
                </Menu.Item>
                <Menu.Item key="/categories" onClick={() => handleMenuClick('/categories')}>
                    工具分类
                </Menu.Item>
                <Menu.Item key="/about" onClick={() => handleMenuClick('/about')}>
                    关于我们
                </Menu.Item>
                <Menu.Item key="/contact" onClick={() => handleMenuClick('/contact')}>
                    联系
                </Menu.Item>
            </Menu>
            {/* <Input.Search placeholder="搜索工具..." className="search" />
            <Button type="primary">登录</Button>
            <Button>注册</Button> */}
        </header>
    );
};

export default Header; 