import React, { useState, useMemo, useCallback } from 'react';
import { Row, Col, Card, Typography, Input, Tag, Button, Empty } from 'antd';
import { useDebounce } from '../hooks/useDebounce';
import './Categories.scss';
import { useNavigate } from 'react-router-dom';
import { toolCategories, Category } from '../data/tools';

const { Title, Paragraph } = Typography;

const Categories: React.FC = () => {
    const [searchValue, setSearchValue] = useState('');
    // 使用防抖处理搜索值
    const debouncedSearchValue = useDebounce(searchValue, 300);
    const navigate = useNavigate();

    // 创建搜索索引
    const searchIndex = useMemo(() => {
        const index = new Map<string, {
            categoryIndex: number;
            toolNames: Set<string>;
        }>();
        
        toolCategories.forEach((category, categoryIndex) => {
            category.tools.forEach(tool => {
                const keywords = [
                    tool.name.toLowerCase(),
                    tool.description.toLowerCase(),
                    category.title.toLowerCase()
                ].join(' ').split(/\s+/);
                
                keywords.forEach(keyword => {
                    if (!index.has(keyword)) {
                        index.set(keyword, {
                            categoryIndex,
                            toolNames: new Set([tool.name])
                        });
                    } else {
                        const entry = index.get(keyword);
                        if (entry) {
                            entry.toolNames.add(tool.name);
                        }
                    }
                });
            });
        });
        
        return index;
    }, []);

    // 优化搜索函数
    const getFilteredCategories = useCallback((searchTerm: string) => {
        if (!searchTerm.trim()) {
            return toolCategories;
        }

        const searchTerms = searchTerm.toLowerCase().trim().split(/\s+/);
        const matchedCategoryIndexes = new Set<number>();

        searchTerms.forEach((term: string) => {
            Array.from(searchIndex.entries()).forEach(([keyword, data]) => {
                if (keyword.startsWith(term)) {
                    matchedCategoryIndexes.add(data.categoryIndex);
                }
            });
        });

        return toolCategories
            .map((category, index) => {
                if (!matchedCategoryIndexes.has(index)) {
                    return null;
                }

                const filteredTools = category.tools.filter(tool => {
                    const toolText = `${tool.name} ${tool.description}`.toLowerCase();
                    return searchTerms.some(term => toolText.includes(term));
                });

                return filteredTools.length > 0
                    ? { ...category, tools: filteredTools }
                    : null;
            })
            .filter((category): category is Category => category !== null);
    }, [searchIndex]);

    // 使用 useMemo 缓存过滤结果
    const filteredCategories = useMemo(() => 
        getFilteredCategories(debouncedSearchValue),
        [debouncedSearchValue, getFilteredCategories]
    );

    // 计算搜索结果总数
    const totalResults = useMemo(() => {
        return filteredCategories.reduce((acc, category) => acc + category.tools.length, 0);
    }, [filteredCategories]);

    // 搜索处理函数
    const handleSearch = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    const handleToolClick = (categoryId: string, toolName: string) => {
        navigate(`/tool/${categoryId}/${encodeURIComponent(toolName)}`);
    };

    return (
        <div className="categories-page">
            {/* 顶部搜索区域 */}
            <section className="search-section">
                <Title level={2}>工具分类</Title>
                <Paragraph>便捷、高效的在线工具集，提供多种实用功能</Paragraph>
                <Input.Search
                    size="large"
                    placeholder="搜索工具..."
                    allowClear
                    onChange={e => handleSearch(e.target.value)}
                    className="search-input"
                    suffix={
                        debouncedSearchValue ? 
                        <span className="search-count">
                            找到 {totalResults} 个工具
                        </span> : null
                    }
                />
            </section>

            {/* 分类列表 */}
            <section className="category-list">
                {filteredCategories.length > 0 ? (
                    filteredCategories.map(category => (
                        <div key={category.id} className="category-section">
                            <div className="category-header">
                                <Title level={3}>
                                    <span className="category-icon">{category.icon}</span>
                                    {category.title}
                                </Title>
                                <Paragraph>{category.description}</Paragraph>
                            </div>
                            <Row gutter={[16, 16]}>
                                {category.tools.map(tool => (
                                    <Col xs={24} sm={12} md={8} lg={6} key={tool.name}>
                                        <Card 
                                            hoverable 
                                            className="tool-card"
                                            data-category={category.id}
                                        >
                                            {tool.hot && <Tag color="red">热门</Tag>}
                                            <Title level={4}>
                                                <span className="tool-icon">{tool.icon}</span>
                                                {tool.name}
                                            </Title>
                                            <Paragraph>{tool.description}</Paragraph>
                                            <div className="card-footer">
                                                <Button 
                                                    type="primary" 
                                                    className="use-button"
                                                    onClick={() => handleToolClick(category.id, tool.name)}
                                                >
                                                    立即使用
                                                </Button>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                未找到与 "{searchValue}" 相关的工具
                            </span>
                        }
                    />
                )}
            </section>
        </div>
    );
};

export default Categories; 