import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Upload, Button, Input, Space, message, Card, Slider, Radio, Image as AntImage } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { debounce } from 'lodash';
import './ImageResizer.scss';

const MIN_SIZE = 8;
const MAX_SIZE = 16384;
const RECOMMENDED_MIN_SCALE = 0.05;
const RECOMMENDED_MAX_SCALE = 8;
const PREVIEW_THROTTLE_TIME = 500;

interface ImageInfo {
    width: number;
    height: number;
    aspectRatio: number;
}

const ImageResizer: React.FC = (): JSX.Element => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [width, setWidth] = useState<string>('');
    const [height, setHeight] = useState<string>('');
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [originalImage, setOriginalImage] = useState<ImageInfo | null>(null);
    const [lockAspectRatio, setLockAspectRatio] = useState(true);
    const [quality, setQuality] = useState(90);
    const [resizeMode, setResizeMode] = useState<'contain' | 'cover'>('contain');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [previewResized, setPreviewResized] = useState<string>('');

    // 处理图片上传
    const handleUpload = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                const imageInfo = {
                    width: img.width,
                    height: img.height,
                    aspectRatio: img.width / img.height
                };
                setOriginalImage(imageInfo);
                // 设置默认尺寸为原图尺寸
                setWidth(String(img.width));
                setHeight(String(img.height));
                setPreviewUrl(e.target?.result as string);
            };
            img.src = e.target?.result as string;
        };
        reader.readAsDataURL(file);
        return false;
    };

    // 处理宽度变化
    const handleWidthChange = (newWidth: string) => {
        const numWidth = Number(newWidth.replace(/\D/g, ''));
        
        // 限制在有效范围内
        const limitedWidth = Math.min(MAX_SIZE, Math.max(MIN_SIZE, numWidth || MIN_SIZE));
        setWidth(String(limitedWidth));
        
        // 检查是否超出建议范围
        if (originalImage) {
            const scale = limitedWidth / originalImage.width;
            if (scale < RECOMMENDED_MIN_SCALE) {
                message.warning('当前缩放比例过小，可能影响图片质量');
            } else if (scale > RECOMMENDED_MAX_SCALE) {
                message.warning('当前放大比例过大，可能导致图片模糊');
            }
        }
        
        if (lockAspectRatio && originalImage) {
            const newHeight = Math.round(limitedWidth / originalImage.aspectRatio);
            const limitedHeight = Math.min(MAX_SIZE, Math.max(MIN_SIZE, newHeight));
            setHeight(String(limitedHeight));
        }
    };

    // 处理高度变化
    const handleHeightChange = (newHeight: string) => {
        const numHeight = Number(newHeight.replace(/\D/g, ''));
        
        // 限制在有效范围内
        const limitedHeight = Math.min(MAX_SIZE, Math.max(MIN_SIZE, numHeight || MIN_SIZE));
        setHeight(String(limitedHeight));
        
        // 检查是否超出建议范围
        if (originalImage) {
            const scale = limitedHeight / originalImage.height;
            if (scale < RECOMMENDED_MIN_SCALE) {
                message.warning('当前缩放比例过小，可能影响图片质量');
            } else if (scale > RECOMMENDED_MAX_SCALE) {
                message.warning('当前放大比例过大，可能导致图片模糊');
            }
        }
        
        if (lockAspectRatio && originalImage) {
            const newWidth = Math.round(limitedHeight * originalImage.aspectRatio);
            const limitedWidth = Math.min(MAX_SIZE, Math.max(MIN_SIZE, newWidth));
            setWidth(String(limitedWidth));
        }
    };

    // 优化预览更新函数
    const updatePreview = useMemo(() => 
        debounce(async () => {
            if (!previewUrl || !width || !height) {
                setPreviewResized('');
                return;
            }

            try {
                const numWidth = Math.max(MIN_SIZE, Number(width));
                const numHeight = Math.max(MIN_SIZE, Number(height));

                const img = await loadImage(previewUrl);
                const canvas = previewCanvasRef.current;
                if (!canvas) return;

                canvas.width = numWidth;
                canvas.height = numHeight;
                const ctx = canvas.getContext('2d');
                if (!ctx) return;

                await drawImageWithQuality(ctx, img, numWidth, numHeight);
                setPreviewResized(canvas.toDataURL('image/png', quality / 100));
            } catch (error) {
                console.error('Error updating preview:', error);
                message.error('图片处理失败，请重试');
            }
        }, PREVIEW_THROTTLE_TIME),
        [previewUrl, width, height, resizeMode, quality]
    );

    // 图片加载辅助函数
    const loadImage = (src: string): Promise<HTMLImageElement> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = src;
        });
    };

    // 当相关参数改变时更新预览
    useEffect(() => {
        updatePreview();
    }, [width, height, resizeMode, quality]);

    // 处理尺寸调整和下载
    const handleResize = () => {
        if (!previewResized) {
            message.error('请先上传图片并设置尺寸');
            return;
        }

        // 直接使用预览图片进行下载
        const a = document.createElement('a');
        a.href = previewResized;
        a.download = 'resized-image.png';
        a.click();
    };

    // 优化图片处理函数
    const drawImageWithQuality = (
        ctx: CanvasRenderingContext2D,
        img: HTMLImageElement,
        targetWidth: number,
        targetHeight: number
    ) => {
        ctx.clearRect(0, 0, targetWidth, targetHeight);
        
        // 使用高质量设置
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        // 计算缩放比例
        const scaleX = targetWidth / img.width;
        const scaleY = targetHeight / img.height;
        const scale = resizeMode === 'contain' ? Math.min(scaleX, scaleY) : Math.max(scaleX, scaleY);

        // 使用多步缩放来提高质量
        const tempCanvas = document.createElement('canvas');
        let currentWidth = img.width;
        let currentHeight = img.height;
        let currentImage: CanvasImageSource = img;

        // 如果是放大
        if (scale > 1) {
            // 使用更多的步骤进行放大
            const steps = Math.ceil(Math.log2(scale)) * 2; // 增加步骤数
            const stepScale = Math.pow(scale, 1 / steps);

            for (let i = 0; i < steps; i++) {
                const nextWidth = Math.round(currentWidth * stepScale);
                const nextHeight = Math.round(currentHeight * stepScale);

                tempCanvas.width = nextWidth;
                tempCanvas.height = nextHeight;
                const tempCtx = tempCanvas.getContext('2d')!;
                tempCtx.imageSmoothingEnabled = true;
                tempCtx.imageSmoothingQuality = 'high';

                // 应用更强的图像增强
                tempCtx.filter = 'contrast(1.05) brightness(1.02) saturate(1.05)';
                tempCtx.drawImage(currentImage, 0, 0, nextWidth, nextHeight);

                // 应用锐化
                const imageData = tempCtx.getImageData(0, 0, nextWidth, nextHeight);
                const sharpenedData = applySharpen(imageData);
                tempCtx.putImageData(sharpenedData, 0, 0);

                currentImage = tempCanvas;
                currentWidth = nextWidth;
                currentHeight = nextHeight;
            }
        } 
        // 如果是缩小
        else if (scale < 1) {
            // 使用更高的超采样倍数
            const supersampleScale = 4; // 增加超采样倍数
            tempCanvas.width = img.width * supersampleScale;
            tempCanvas.height = img.height * supersampleScale;
            const tempCtx = tempCanvas.getContext('2d')!;
            tempCtx.imageSmoothingEnabled = true;
            tempCtx.imageSmoothingQuality = 'high';

            // 先放大到超采样尺寸
            tempCtx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height);

            // 应用锐化
            const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
            const sharpenedData = applySharpen(imageData);
            tempCtx.putImageData(sharpenedData, 0, 0);

            // 创建第二个临时画布用于渐进式缩小
            const tempCanvas2 = document.createElement('canvas');
            let currentSize = { width: tempCanvas.width, height: tempCanvas.height };
            
            while (currentSize.width > targetWidth * 1.2) {
                currentSize = {
                    width: Math.max(targetWidth, Math.round(currentSize.width * 0.7)),
                    height: Math.max(targetHeight, Math.round(currentSize.height * 0.7))
                };

                tempCanvas2.width = currentSize.width;
                tempCanvas2.height = currentSize.height;
                const tempCtx2 = tempCanvas2.getContext('2d')!;
                tempCtx2.imageSmoothingEnabled = true;
                tempCtx2.imageSmoothingQuality = 'high';

                // 每一步都应用轻微的图像增强
                tempCtx2.filter = 'contrast(1.02) brightness(1.01)';
                tempCtx2.drawImage(tempCanvas, 0, 0, currentSize.width, currentSize.height);

                // 更新源画布
                tempCanvas.width = currentSize.width;
                tempCanvas.height = currentSize.height;
                tempCtx.drawImage(tempCanvas2, 0, 0);
            }

            currentImage = tempCanvas;
            currentWidth = currentSize.width;
            currentHeight = currentSize.height;
        }

        // 计算最终位置
        const finalWidth = img.width * scale;
        const finalHeight = img.height * scale;
        const x = (targetWidth - finalWidth) / 2;
        const y = (targetHeight - finalHeight) / 2;

        // 最终绘制
        ctx.drawImage(currentImage, 0, 0, currentWidth, currentHeight, x, y, finalWidth, finalHeight);

        // 应用最终的图像增强
        if (quality >= 90) {
            const imageData = ctx.getImageData(0, 0, targetWidth, targetHeight);
            const enhancedData = enhanceImage(imageData);
            ctx.putImageData(enhancedData, 0, 0);
        }
    };

    // 优化锐化算法
    const applySharpen = (imageData: ImageData): ImageData => {
        const { width, height, data } = imageData;
        const output = new ImageData(width, height);
        const outputData = output.data;
        
        // 使用更强的锐化卷积核
        const kernel = [
            -0.1, -0.15, -0.1,
            -0.15, 2.0, -0.15,
            -0.1, -0.15, -0.1
        ];

        for (let y = 1; y < height - 1; y++) {
            for (let x = 1; x < width - 1; x++) {
                const idx = (y * width + x) * 4;
                let r = 0, g = 0, b = 0;

                // 应用卷积
                for (let ky = -1; ky <= 1; ky++) {
                    for (let kx = -1; kx <= 1; kx++) {
                        const offset = ((y + ky) * width + (x + kx)) * 4;
                        const kernelValue = kernel[(ky + 1) * 3 + (kx + 1)];
                        
                        r += data[offset] * kernelValue;
                        g += data[offset + 1] * kernelValue;
                        b += data[offset + 2] * kernelValue;
                    }
                }

                // 应用更强的对比度增强
                outputData[idx] = Math.min(255, Math.max(0, r + (r - 128) * 0.1));
                outputData[idx + 1] = Math.min(255, Math.max(0, g + (g - 128) * 0.1));
                outputData[idx + 2] = Math.min(255, Math.max(0, b + (b - 128) * 0.1));
                outputData[idx + 3] = data[idx + 3];
            }
        }

        return output;
    };

    // 图像增强函数
    const enhanceImage = (imageData: ImageData): ImageData => {
        const { data, width, height } = imageData;
        const enhancedData = new Uint8ClampedArray(data);

        for (let i = 0; i < data.length; i += 4) {
            // 提高对比度和锐度
            for (let j = 0; j < 3; j++) {
                const value = data[i + j];
                // 增强中间调
                enhancedData[i + j] = Math.min(255, Math.max(0,
                    value + (value - 128) * 0.1
                ));
            }
            enhancedData[i + 3] = data[i + 3]; // 保持 alpha 通道不变
        }

        // 正确创建并返回 ImageData
        return new window.ImageData(enhancedData, width, height);
    };

    // 修复 originalImage 可能为 null 的警告
    const scaleInfo = originalImage ? (
        <div className="current-scale">
            当前缩放比例：
            {Math.round((Number(width) / originalImage.width) * 100)}% x {Math.round((Number(height) / originalImage.height) * 100)}%
        </div>
    ) : null;

    return (
        <div className="image-resizer">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                {/* 上传区域 */}
                <Card title="1. 选择图片">
                    <Upload
                        accept="image/*"
                        maxCount={1}
                        fileList={fileList}
                        beforeUpload={handleUpload}
                        onChange={({ fileList }) => setFileList(fileList)}
                    >
                        <Button icon={<UploadOutlined />}>选择图片</Button>
                    </Upload>
                </Card>

                {/* 尺寸设置 */}
                <Card title="2. 设置尺寸">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div className="size-controls">
                            <Input
                                addonBefore="宽度"
                                addonAfter="px"
                                value={width}
                                onChange={e => handleWidthChange(e.target.value)}
                            />
                            <Input
                                addonBefore="高度"
                                addonAfter="px"
                                value={height}
                                onChange={e => handleHeightChange(e.target.value)}
                            />
                        </div>

                        <div className="size-limits-info">
                            <small>• 支持的尺寸范围：{MIN_SIZE}px ~ {MAX_SIZE}px</small>
                            <small>• 建议缩放范围：原尺寸的{RECOMMENDED_MIN_SCALE * 100}% ~ {RECOMMENDED_MAX_SCALE * 100}%</small>
                            {scaleInfo}
                        </div>

                        <div className="control-buttons">
                            <Button onClick={() => originalImage && setWidth(String(originalImage.width))}>
                                原始宽度
                            </Button>
                            <Button onClick={() => originalImage && setHeight(String(originalImage.height))}>
                                原始高度
                            </Button>
                        </div>

                        <div className="mode-controls">
                            <Radio.Group value={lockAspectRatio} onChange={e => setLockAspectRatio(e.target.value)}>
                                <Radio value={true}>锁定宽高比</Radio>
                                <Radio value={false}>自由调整</Radio>
                            </Radio.Group>

                            <Radio.Group value={resizeMode} onChange={e => setResizeMode(e.target.value)}>
                                <Radio value="contain">保持比例</Radio>
                                <Radio value="cover">填充</Radio>
                            </Radio.Group>
                        </div>

                        <div className="quality-control">
                            <div className="quality-label">
                                <span>图片质量</span>
                                <span className="quality-value">{quality}%</span>
                            </div>
                            <Slider min={1} max={100} value={quality} onChange={setQuality} />
                        </div>
                    </Space>
                </Card>

                {/* 预览区域 */}
                {previewUrl && (
                    <Card 
                        title="3. 预览"
                        extra={
                            <Radio.Group value={previewResized ? 'after' : 'before'}>
                                <Radio.Button value="before" onClick={() => setPreviewResized('')}>
                                    原图
                                </Radio.Button>
                                <Radio.Button value="after" onClick={updatePreview}>
                                    预览效果
                                </Radio.Button>
                            </Radio.Group>
                        }
                    >
                        <div className="preview-area">
                            <div className="image-container">
                                <div className="image-wrapper">
                                    <h4>原图</h4>
                                    <AntImage
                                        src={previewUrl}
                                        alt="原图"
                                        className="preview-image"
                                    />
                                </div>
                                {previewResized && (
                                    <div className="image-wrapper">
                                        <h4>调整后</h4>
                                        <AntImage
                                            src={previewResized}
                                            alt="调整后"
                                            className="preview-image"
                                            style={{
                                                maxHeight: Number(height)
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>
                )}

                {/* 下载按钮 */}
                <div className="download-button">
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleResize}
                        disabled={!previewResized}
                        block
                    >
                        下载调整后的图片
                    </Button>
                </div>
            </Space>
            {/* 隐藏的canvas */}
            <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
        </div>
    );
};

export default ImageResizer;
