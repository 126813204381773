import React from 'react';
import { Layout, Typography } from 'antd';
import './Footer.scss';

const { Text, Link } = Typography;

const Footer: React.FC = () => {
    return (
        <Layout.Footer className="app-footer">
            <div className="footer-content">
                <div className="footer-links">
                    <Link href="/about">关于我们</Link>
                    <Link href="/contact">联系我们</Link>
                    <Link href="https://github.com/toolportal" target="_blank">GitHub</Link>
                </div>
                <Text className="copyright">
                    © {new Date().getFullYear()} Tool Portal. All rights reserved.
                </Text>
            </div>
        </Layout.Footer>
    );
};

export default Footer; 